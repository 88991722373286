import { useStaticQuery, graphql } from "gatsby"

const useOpeningTimes = () => {
  const data = useStaticQuery(graphql`
    {
      __typename
      markdownRemark(fileAbsolutePath: { regex: "/opening-times.md/" }) {
        frontmatter {
          title
          monday_opening
          monday_closing
          monday_opening
          monday_closing
          tuesday_opening
          tuesday_closing
          wednesday_opening
          wednesday_closing
          thursday_opening
          thursday_closing
          friday_opening
          friday_closing
          saturday_opening
          saturday_closing
          sunday_opening
          sunday_closing
        }
      }
    }
  `)
  return data
}

export default useOpeningTimes
