import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"

import { useIntl } from "gatsby-plugin-intl"

const IndexPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "menu_item.contact" })} />
      <Contact />
    </Layout>
  )
}

export default IndexPage
