import React from 'react'
import { css } from "@emotion/core"
import { useIntl } from "gatsby-plugin-intl"
import useOpeningTimes from "../hooks/useOpeningTimes"
import {FINNISH_HOURS_TO_ENGLISH_HOURS} from '../util/constants'

const openingTimesTitleStyle = css`
  margin-bottom: 2rem;
`

const hoursStyle = css`
  font-size: 1rem;
`

const hoursInLocale = (locale, time) => {
  return locale === 'fi' ? time : FINNISH_HOURS_TO_ENGLISH_HOURS[time];
}

export const OpeningTimes = () => {
  const intl = useIntl()
  const openingTimesQuery = useOpeningTimes()
  const openingTimes = openingTimesQuery.markdownRemark.frontmatter;
  
  const locale = intl.locale;

  return (
    <>
      <p css={openingTimesTitleStyle}>{intl.formatMessage({ id: "opening_times.alakerta_closed_for_summer" })}</p>
      <p css={openingTimesTitleStyle}>{intl.formatMessage({ id: "opening_times.lift_open_for_summer" })}</p>
      <p css={openingTimesTitleStyle}>{intl.formatMessage({ id: "opening_times.check_times_from_facebook" })}</p>
      {/* <p css={openingTimesTitleStyle}>{intl.formatMessage({ id: "opening_times.title" })}:</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.monday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.monday_opening)} - ${hoursInLocale(locale, openingTimes.monday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.tuesday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.tuesday_opening)} - ${hoursInLocale(locale, openingTimes.tuesday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.wednesday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.wednesday_opening)} - ${hoursInLocale(locale, openingTimes.wednesday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.thursday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.thursday_opening)} - ${hoursInLocale(locale, openingTimes.thursday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.friday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.friday_opening)} - ${hoursInLocale(locale, openingTimes.friday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.saturday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.saturday_opening)} - ${hoursInLocale(locale, openingTimes.saturday_closing)}`}</p>
      <p css={hoursStyle}>{intl.formatMessage({ id: "opening_times.sunday.name" })}&nbsp;&nbsp;&nbsp;{`${hoursInLocale(locale, openingTimes.sunday_opening)} - ${hoursInLocale(locale, openingTimes.sunday_closing)}`}</p> */}
    </>
  )
}
