import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import alakertaLogo from "../images/logo.svg"
import facebookLogo from "../images/facebook_gradient.svg"
import instagramLogo from "../images/instagram_gradient.svg"
import phoneLogo from "../images/phone.svg"
import locationLogo from "../images/pin.svg"
import mailLogo from "../images/mail.svg"
import {
  ALAKERTA_DARK_BACKGROUND,
  ALAKERTA_LIGHT_YELLOW,
  ALAKERTA_GREEN,
  SECONDARY_FONT,
} from "../util/constants"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {OpeningTimes} from './OpeningTimes';

import { useIntl } from "gatsby-plugin-intl"

const Container = styled.div`
  background: ${ALAKERTA_DARK_BACKGROUND};
  background: linear-gradient(${ALAKERTA_GREEN}, ${ALAKERTA_DARK_BACKGROUND});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const SomeIconContainer = styled.div`
  display: flex;
  margin: 4rem 0 5rem 0;
`

const alakertaLogoStyle = css`
  width: 50vw;
  max-width: 10rem;
  margin: 3.45rem 0;
  animation: flip-in-ver-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`

const alakertaLogoTitle = css`
  color: white;
  font-family: "Alakerta Part 2 Regular", sans-serif;
  font-size: 1.5rem;
`

const frontPageTextStyle = css`
  color: white;
  font-family: ${SECONDARY_FONT};
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
  word-break: normal;
  word-wrap: normal;
`

const someIconStyle = css`
  width: 3rem;
  margin: 0.5rem 0.75rem;
  box-shadow: 0px 0px 12px 0px rgba(252, 247, 215, 0.5);
  border-radius: 0.45rem;
  // Following are for alt text to be visible
  color: white;
  font-size: 0.5rem;
  font-family: ${SECONDARY_FONT};
`

const contactIconStyle = css`
  height: 1.25rem;
  margin: 0 0.5rem 0 0;
`

const ContactContainer = styled.div`
  margin: 1rem 0;
`

const contactLinkStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline ${ALAKERTA_LIGHT_YELLOW};
  color: white;
  font-size: 1rem;
`

const Contact = () => {
  const intl = useIntl()
  
  const data = useStaticQuery(graphql`
  query {
    handsLeft: file(relativePath: { eq: "hands_left.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }

    handsRight: file(relativePath: { eq: "hands_right.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`)

  return (
    <Container>
      <img css={alakertaLogoStyle} src={alakertaLogo} alt="Alakerta Bar logo" />
      <h1 css={alakertaLogoTitle}>BAR ALAKERTA</h1>
      <div
        css={[
          frontPageTextStyle,
          css`
            margin: 3rem 0 0 0;
          `,
        ]}
      >
        <OpeningTimes />
      </div>
      <div
        css={[
          frontPageTextStyle,
          css`
            margin: 1.5rem 0 0 0;
          `,
        ]}
      >
        <ContactContainer>
          <a
            href="https://www.google.com/maps/search/Bar+Alakerta/@67.8046419,24.806708,17z"
            target="_blank"
            rel="noreferrer noopener"
            css={contactLinkStyle}
          >
            <img
              css={contactIconStyle}
              src={locationLogo}
              alt="Bar Alakerta's address"
            />
            <span>Hissitie 3, 99130 Sirkka</span>
          </a>
        </ContactContainer>
        <ContactContainer>
          <a
            href="tel:+358-41-3140421"
            target="_blank"
            rel="noreferrer noopener"
            css={contactLinkStyle}
          >
            <img
              css={contactIconStyle}
              src={phoneLogo}
              alt="Call Bar Alakerta"
            />
            <span>+358 41 3140421</span>
          </a>
        </ContactContainer>
        <ContactContainer>
          <a
            href="mailto:alakertabar@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
            css={contactLinkStyle}
          >
            <img
              css={contactIconStyle}
              src={mailLogo}
              alt="Email Bar Alakerta"
            />
            <span>alakertabar@gmail.com</span>
          </a>
        </ContactContainer>
      </div>
      <SomeIconContainer>
        <a
          href="https://www.facebook.com/baralakerta/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            css={someIconStyle}
            src={facebookLogo}
            alt="Link to Alakerta's Facebook"
          />
        </a>
        <a
          href="https://www.instagram.com/baralakerta/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            css={someIconStyle}
            src={instagramLogo}
            alt="Link to Alakerta's Instagram"
          />
        </a>
      </SomeIconContainer>
      <Img
        fluid={data.handsLeft.childImageSharp.fluid}
        className="front-page-hands-image"
        alt="Purple hand and microphone"
        style={{
          width: '30%',
          maxWidth: '400px',
          zIndex: -1,
          position: 'fixed',
          bottom: 0,
          left: 0
        }}
      />
      <Img
        fluid={data.handsRight.childImageSharp.fluid}
        className="front-page-hands-image"
        alt="Purple hand and casette"
        style={{
          width: '40%',
          maxWidth: '400px',
          zIndex: -1,
          position: 'fixed',
          bottom: 0,
          right: 0
        }}
      />
    </Container>
  )
}

export default Contact
